/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
// import './src/styles/tailwind.css'
import "./src/styles/app.css"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey="6LeI4CIjAAAAAKOevbizVzu4jOWj8biRFbAynjg0">
    {element}
  </GoogleReCaptchaProvider>
)
